import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SidebarGroup from './SidebarGroup';

const Sidebar = ({ groupInfo, icons, currentUser, logout }) => {
  const [sidebarShow, setSidebarShow] = useState(window.innerWidth > 768 ? true : false);
  const currentPath = window.location.pathname;

  const { arrow, burger } = icons;

  const handleHighlight = (link) => {
    if (currentPath.indexOf(link) > -1) {
      return 'sidebar__menu__item --highlight';
    }
    return 'sidebar__menu__item';
  };

  const handleDefaultDropdown = (links) => (
    Object.values(links).some((link) => currentPath.indexOf(link) > -1)
  );

  return (
    <nav className="sidebar">
      <div
        className={`sidebar__overlay ${sidebarShow ? '' : '--hidden'}`}
        onClick={() => setSidebarShow(false)}
      />

      <button
        type="button"
        className="sidebar__burger"
        dangerouslySetInnerHTML={{ __html: burger }}
        onClick={() => setSidebarShow(true)}
      />

      <a href="/" className="d-flex flex-column justify-content-center h-100 align-items-center sidebar__logo p-3">
        <div dangerouslySetInnerHTML={{ __html: icons.logo }} />
        <p>Sistema de Gestión</p>
      </a>

      <div className="sidebar__user" dangerouslySetInnerHTML={{ __html: logout }} />

      <div className={`sidebar__menu ${sidebarShow ? '' : '--hidden'}`}>
        <div className="sidebar__menu__user">
          <img className="sidebar__menu__user__image" src={icons.profile} />
          <p>
            Hola,
            <strong className="d-block">{currentUser.name} {currentUser.last_name}</strong>
          </p>
        </div>

        <hr />

        {Object.keys(groupInfo).map(key => 
          <SidebarGroup
            group={groupInfo[key]}
            arrow={arrow}
            key={key}
            defaultDropdown={handleDefaultDropdown(groupInfo[key].links)}
          />
        )}
      </div>
    </nav>
  );
};

Sidebar.propTypes = {
  groupInfo: PropTypes.objectOf(PropTypes.object).isRequired,
  icons: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Sidebar;
