import React, { useEffect, useState, useReducer } from "react";
import consumer from "../../channels/consumer";
import reducer from "./chatReducer";
import LinkModal from "./LinkModal";

const sendIcon = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.67124 17.337L19.1212 9.85703C19.9312 9.50703 19.9312 8.36703 19.1212 8.01703L1.67124 0.537029C1.01124 0.247029 0.28124 0.737029 0.28124 1.44703L0.27124 6.05703C0.27124 6.55703 0.64124 6.98703 1.14124 7.04703L15.2712 8.93703L1.14124 10.817C0.64124 10.887 0.27124 11.317 0.27124 11.817L0.28124 16.427C0.28124 17.137 1.01124 17.627 1.67124 17.337Z"
      fill="#CF2032"
    />
  </svg>
);

const LiveChat = ({ liveChat, messages, onMessageSelect }) => {
  const [state, dispatch] = useReducer(reducer, {
    chatroom: { name: liveChat.name, id: liveChat.id, messages: messages },
    errors: [],
    chatConnection: {},
  });

  const [directMessage, setDirectMessage] = useState("");
  const [messageLink, setMessageLink] = useState(null);
  const [allowSend, setAllowSend] = useState(false);
  const { chatroom, chatConnection } = state;

  useEffect(() => {
    const createSocket = () => {
      const subscription = consumer.subscriptions.create(
        {
          channel: "LiveChatChannel",
          room: chatroom.name,
        },
        {
          received: (data) => {
            const { action, message } = data;
            dispatch({ type: action, payload: message });
          },
        }
      );
      dispatch({ type: "chatConnection", payload: subscription });
    };

    if (chatroom.name) {
      createSocket();
    }
  }, [chatroom.name, chatroom.id]);

  useEffect(() => {
    setAllowSend(!directMessage.trim() == "");
  }, [directMessage, messageLink]);

  const handleSend = () => {
    if (directMessage.trim === "") return;

    chatConnection.send({
      message: {
        content: directMessage,
        chatroom_id: chatroom.id,
        url: messageLink,
      },
      commenter: {
        uid: "tvin",
      },
    });

    setMessageLink(null);
    setDirectMessage("");
  };

  const sendOnEnter = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <>
      <div className="live-chat__comments">
        {chatroom.messages.map((m) => (
          <button
            onClick={() => onMessageSelect(m)}
            key={`${m.name}-${m.id}`}
            className={`live-chat__comments__item ${
              m.featured ? "--featured" : ""
            }`}
          >
            <img src={m.avatar} referrerPolicy="no-referrer" />
            <div>
              <span>
                <strong>{m.name}</strong>
                {m.time}
              </span>
              <p>{m.content}</p>
            </div>
          </button>
        ))}
      </div>

      <div className="live-chat__input">
        <textarea
          rows={1}
          onKeyDown={sendOnEnter}
          value={directMessage}
          onChange={({ target }) => setDirectMessage(target.value)}
          placeholder="Agregar mensaje destacado"
        />

        <LinkModal
          currentLink={messageLink}
          onSetValidity={setAllowSend}
          onLinkSet={setMessageLink}
        />

        <button
          disabled={!allowSend}
          type="button"
          onClick={handleSend}
          title="Enviar"
        >
          {sendIcon}
        </button>
      </div>
    </>
  );
};

export default LiveChat;
