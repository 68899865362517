import React from "react";
import DisplayPreview from "./DisplayPreview";

const Display = ({
  articlesRails,
  urls,
  authenticityToken,
  cardOptionsRails,
  quantity
}) => (
  <DisplayPreview
    urls={urls}
    articlesRails={articlesRails}
    authenticityToken={authenticityToken}
    cardOptionsRails={cardOptionsRails}
    size={quantity}
  />
);

export default Display;
