const reducer = (state, action) => {
  switch (action.type) {
    case "messageNew": {
      const updatedChatroom = { ...state.chatroom };
      updatedChatroom.messages = [action.payload, ...state.chatroom.messages];
      return { ...state, chatroom: updatedChatroom };
    }
    case "messageRemove": {
      const updatedChatroom = { ...state.chatroom };
      updatedChatroom.messages = [
        ...state.chatroom.messages.filter((m) => m.id !== action.payload.id),
      ];
      return { ...state, chatroom: updatedChatroom };
    }
    case "messagePromote": {
      const updatedChatroom = { ...state.chatroom };
      const prevFeatured = state.chatroom.messages.find((m) => m.featured);
      if (prevFeatured) prevFeatured["featured"] = false;

      const nextFeatured = state.chatroom.messages.find(
        (m) => m.id === action.payload.id
      );
      nextFeatured["featured"] = true;
      
      if (action.payload.url) {
        nextFeatured["url"] = action.payload.url;
      }

      return { ...state, chatroom: updatedChatroom };
    }
    case "chatConnection": {
      return { ...state, chatConnection: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
