import React from "react";
import OttVideosForm from "./OttVideosForm";
import { CreateVideoContext } from "../../contexts/CreateVideoContext";

const CreateVideo = ({ uploadData, currentValues, tags, statuses }) => {
  return (
    <CreateVideoContext.Provider value={{ uploadData, currentValues, tags, statuses }}>
      <OttVideosForm />
    </CreateVideoContext.Provider>
  );
};

export default CreateVideo;
