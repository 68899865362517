import React from "react";
import { useState } from "react";

const confirm = (
  <svg
    fill="#ffffff"
    width="64px"
    height="64px"
    viewBox="0 0 14.00 14.00"
    role="img"
    focusable="false"
    ariaHidden="true"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#ffffff"
    strokeWidth="0.00014"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path d="m 13,4.1974 q 0,0.3097 -0.21677,0.5265 l -5.60517,5.6051 -1.0529,1.0529 q -0.21677,0.2168 -0.52645,0.2168 -0.30968,0 -0.52645,-0.2168 L 4.01935,10.329 1.21677,7.5264 Q 1,7.3097 1,7 1,6.6903 1.21677,6.4735 L 2.26968,5.4206 q 0.21677,-0.2167 0.52645,-0.2167 0.30968,0 0.52645,0.2167 l 2.27613,2.2839 5.07871,-5.0864 q 0.21677,-0.2168 0.52645,-0.2168 0.30968,0 0.52645,0.2168 L 12.78323,3.671 Q 13,3.8877 13,4.1974 z"></path>
    </g>
  </svg>
);

const cancel = (
  <svg
    fill="#ffffff"
    width="64px"
    height="64px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#ffffff"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M19.587 16.001l6.096 6.096c0.396 0.396 0.396 1.039 0 1.435l-2.151 2.151c-0.396 0.396-1.038 0.396-1.435 0l-6.097-6.096-6.097 6.096c-0.396 0.396-1.038 0.396-1.434 0l-2.152-2.151c-0.396-0.396-0.396-1.038 0-1.435l6.097-6.096-6.097-6.097c-0.396-0.396-0.396-1.039 0-1.435l2.153-2.151c0.396-0.396 1.038-0.396 1.434 0l6.096 6.097 6.097-6.097c0.396-0.396 1.038-0.396 1.435 0l2.151 2.152c0.396 0.396 0.396 1.038 0 1.435l-6.096 6.096z"></path>
    </g>
  </svg>
);

const ConfirmAction = ({ children, buttonAtr, text, onConfirm, reverse }) => {
  const [askConfirm, setAskConfirm] = useState(false);

  const handleConfirm = () => {
    setAskConfirm(false);
    onConfirm();
  };

  return askConfirm ? (
    <div className="confirm-overlay">
      <p>{text}</p>
      <div>
        <button
          data-reverse={reverse}
          className="--delete"
          type="button"
          onClick={() => setAskConfirm(false)}
          title="Cancelar"
        >
          {cancel}
        </button>
        <button
          className="--confirm"
          title="Confirmar"
          type="button"
          onClick={handleConfirm}
        >
          {confirm}
        </button>
      </div>
    </div>
  ) : (
    <button {...buttonAtr} type="button" onClick={() => setAskConfirm(true)}>
      {children}
    </button>
  );
};

export default ConfirmAction;
