import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import PopupAddVideo from './PopupAddVideo';

const closeIcon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#E31519"/>
  </svg>
);

const PlaylistPreview = ({ playlistVideos, removeVideo, setPlaylistVideos }) => {
  const [showPopup, setShowPopup] = useState(false);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleRemoveVideo = (videoId) => removeVideo(videoId);

  const handleDragVideo = () => {
    const newVideosOrder = [...playlistVideos]

		//remove and save the dragged item content
		const draggedItemContent = newVideosOrder.splice(dragItem.current, 1)[0]

		//switch the position
		newVideosOrder.splice(dragOverItem.current, 0, draggedItemContent)

		//reset the position ref
    cleanDragReferences();

		//update the actual playlist
    setPlaylistVideos(newVideosOrder);
  }

  const cleanDragReferences = () => {dragItem.current = null; dragOverItem.current = null}

  return (
    <>
      <div className='playlist__preview'>
        <div className='playlist__preview__header'>
          <h2 className='playlist__preview__header__title'>Preview de la Playlist</h2>
          <button className='btn btn-publish' onClick={() => setShowPopup(true)}>
            Añadir videos
          </button>
        </div>
        <div className='playlist__preview__area'>
          {
            playlistVideos.length ?
              playlistVideos.map(({ title, get_thumbnail, url, get_date, id, duration }, index) => (
                <div
                  key={`${title}-${index}`}
                  className='video'
                  draggable
                  onDragStart={() => (dragItem.current = index)}
                  onDragEnter={() => (dragOverItem.current = index)}
                  onDragEnd={handleDragVideo}
                >
                  <div className='video__image'>
                    <img src={ get_thumbnail } alt="" />
                  </div>
                  <p className='video__title'>
                    <a href={ url } target="_blank">{ title }</a>
                    <span>{ get_date }</span>
                  </p>
                  <p className='video__duration'>{ duration }</p>
                  <div className='video__tools' onClick={() => handleRemoveVideo(id)}>
                    {closeIcon}
                  </div>
                </div>
              ))
            :
              <div className='playlist__preview__area__empty'>
                <h3>Tu playlist esta vacía</h3>
                <p>Añade videos para empezar</p>
              </div>
          }
        </div>
        <div className='playlist__preview__footer'>
          <p>Cantidad de Videos: <span>{playlistVideos.length}</span></p>
        </div>
        {
          showPopup &&
          <PopupAddVideo closePopup={() => setShowPopup(false)} />
        }
      </div>
    </>
  )
}

PlaylistPreview.propTypes = {}

export default PlaylistPreview
