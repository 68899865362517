import React, { useContext, useState } from "react";
import MeiliSearch from "meilisearch";
import { CreatePlaylistContext } from "../../contexts/CreatePlaylistContext";
import { useEffect } from "react";

const VideoMeilisearch = React.memo(({ setVideos }) => {
  const [results, setResults] = useState(null);
  const [searchText, setSearchText] = useState("");

  const { formData } = useContext(CreatePlaylistContext);
  const { tags, videos } = formData;

  const formatTags = () => {
    const formatElements = tags.map((tag) => `tags = '${tag.label}'`);
    const result = formatElements.join(" OR ");

    return result;
  };

  const handleChangeSearchText = ({ target }) => {
    setSearchText(target.value);
  };

  const client = new MeiliSearch({
    host: 'https://meili.intoleranciadiario.com',
    apiKey: 'aW50b2xlcmFuY2lhZGlhcmlvcHVlYmxh',
  });

  const searchMeili = async () => {
    const results = await client.index("OttVideo").search(searchText, {
      filter: [formatTags()],
    });

    return results;
  };

  useEffect(() => {
    const fetchAsync = async () => {
      const getResults = await searchMeili();
      setResults(getResults);
    };

    fetchAsync();
  }, [searchText]);

  useEffect(() => {
    if (results) {
      setVideos(results.hits);
    }
  }, [results]);

  return (
    <>
      <input
        type="text"
        className="form-control"
        onChange={handleChangeSearchText}
        value={searchText}
        placeholder="Buscar por título o tags vinculados al video"
      />
    </>
  );
});

export default VideoMeilisearch;
