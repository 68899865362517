import React, { useContext } from "react";
import { CreateVideoContext } from "../../contexts/CreateVideoContext";
import "@mux/mux-player";

const MuxPlayer = () => {
  const {
    currentValues: { videoUid, streamKey },
  } = useContext(CreateVideoContext);

  return <mux-player accent-color="#CF2032" playback-id={videoUid ?? streamKey} />;
};

export default MuxPlayer;
