import React from 'react';
import PropTypes from 'prop-types';

const ArticleTr= ({ article }) => {
    const { title, description, date, urls: { edit } } = article;

    const handleDragArticle= (e) => {    
        e.dataTransfer.setData('article', JSON.stringify(article));
    }

    return (
        <tr draggable onDragStart={handleDragArticle}>
            <td className="td-info">
                <p className="td-title">{ title }</p>
                <p className="td-body">{ description }</p>
            </td>
            <td>{ date }</td>
            <td>
                <div className="options">
                    <a href={edit} className="modify-icon"><i className="fas fa-pen"></i></a>
                </div>
            </td>
        </tr>
    );
}


ArticleTr.propTypes = {
    article: PropTypes.object,
}

export default ArticleTr;
