import React, { useState } from "react";
import PopUp from "../../layout/PopUp";
import ListSort from "./ListSort";

const SortPopup = ({ sortData }) => {
  const [showPopup, setShowPopup] = useState(false);
    
  return (
    <div className="sort-popup">
      <button type="button" onClick={() => setShowPopup(true)}>Reorganizar</button>

      {showPopup && (
        <PopUp closeAction={() => setShowPopup(false)}>
          <ListSort {...sortData} />    
        </PopUp>
      )}
    </div>
  )
};

export default SortPopup;
