import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ArticleTrPopup = ({ article, isActive, setSelectedOption }) => {
    const { id, title, description } = article;

    const handleSelectOption = () => {
        setSelectedOption(article);
    }

    return (
        <tr>
            <td>
                <div className="image-square"></div>
            </td>
            <td>
                <p className="td-title">{ title }</p>
                <p className="td-body">{ description }</p>
            </td>
            <td>4/7/2021</td>
            <td>
                <input
                    className="popup-radio"
                    type="radio"
                    value={ id }
                    name="articleArea"
                    defaultChecked={isActive}
                    onClick={handleSelectOption}
                />
            </td>
        </tr>
    );
}

ArticleTrPopup.propTypes = {
    article: PropTypes.object,
    isActive: PropTypes.bool,
    setSelectedOption: PropTypes.func
}

export default ArticleTrPopup;