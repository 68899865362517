import React, { useState } from "react";
import Select from "react-select";

const SearchSelect = ({ values, options, props }) => {
  const [currentSelects, setCurrentSelects] = useState(
    values.map((option) => ({ value: option.id, label: option.name })) ?? []
  );

  return (
    <>
      <Select
        {...props}
        // isMulti
        // name="tags"
        // placeholder="Buscar o asignar tags"
        // className="basic-multi-select"
        // classNamePrefix="Selecciona uno o mas tags"
        options={options.map((option) => ({
          value: option.id,
          label: option.name,
        }))}
        value={currentSelects}
        onChange={(e) => setCurrentSelects(e)}
      />
    </>
  );
};

export default SearchSelect;
