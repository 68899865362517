import React, { useEffect, useRef, useState } from "react";

const pencilIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M-0.000976562 12.0007H2.49902L9.87236 4.62736L7.37236 2.12736L-0.000976562 9.50069V12.0007ZM1.33236 10.054L7.37236 4.01402L7.98569 4.62736L1.94569 10.6674H1.33236V10.054Z" />
    <path d="M10.2457 0.194023C9.98569 -0.0659766 9.56569 -0.0659766 9.30569 0.194023L8.08569 1.41402L10.5857 3.91402L11.8057 2.69402C12.0657 2.43402 12.0657 2.01402 11.8057 1.75402L10.2457 0.194023Z" />
  </svg>
);

const ListUpdateField = ({ name, route, value, placeholder, id, authenticityToken }) => {
  const [fieldValue, setFieldValue] = useState(value);
  const [saving, setSaving] = useState(false);
  const [active, setActive] = useState(false);
  const inputRef = useRef();

  const handleSubmit = async () => {
    const params = new FormData();
    params.append(name, fieldValue);
    params.append("id", id);
    params.append("authenticity_token", authenticityToken);

    const response = await fetch(route, {
      method: "POST",
      body: params,
    });

    if (response.ok) {
      setSaving(false);
    }
  };

  const handleBlur = () => {
    setActive(false);

    if (saving || value == fieldValue || fieldValue.trim == "") return;

    setSaving(true);
    handleSubmit();
  };

  const handleActive = () => {
    setActive(true);
  };

  useEffect(() => {
    if (active) inputRef.current.focus();
  }, [active]);

  return (
    <>
      {!active && (
        <span onClick={handleActive}>
          {fieldValue ?? placeholder} {pencilIcon}
        </span>
      )}

      {active && (
        <input
          ref={inputRef}
          pattern="^[a-zA-Z ]*$"
          name={name}
          value={fieldValue}
          onBlur={handleBlur}
          onChange={({ target }) => setFieldValue(target.value)}
        />
      )}
    </>
  );
};

export default ListUpdateField;
