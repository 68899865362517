import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

const linkIcon = (
  <svg
    width="64px"
    height="64px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M10.0464 14C8.54044 12.4882 8.67609 9.90087 10.3494 8.22108L15.197 3.35462C16.8703 1.67483 19.4476 1.53865 20.9536 3.05046C22.4596 4.56228 22.3239 7.14956 20.6506 8.82935L18.2268 11.2626"
        stroke="#CF2032"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>
      <path
        d="M13.9536 10C15.4596 11.5118 15.3239 14.0991 13.6506 15.7789L11.2268 18.2121L8.80299 20.6454C7.12969 22.3252 4.55237 22.4613 3.0464 20.9495C1.54043 19.4377 1.67609 16.8504 3.34939 15.1706L5.77323 12.7373"
        stroke="#CF2032"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>
    </g>
  </svg>
);

const LinkModal = ({ currentLink, onLinkSet, onSetValidity }) => {
  const [showModal, setShowModal] = useState(false);
  const [link, setLink] = useState("");
  const urlInput = useRef();
  
  useEffect(() => {
    if (!currentLink) setLink("")
  }, [currentLink])

  const handleLinkSet = () => {
    if (!showModal) {
      setShowModal(true);
      return;
    }

    if (!urlInput.current.reportValidity()) {
      onSetValidity(false);
      return;
    }
    
    onLinkSet(link);
    onSetValidity(true);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="add-link-modal">
          <h3>Agregar URL al comentario</h3>
          <input
            value={link}
            onChange={({ target }) => setLink(target.value)}
            onBlur={handleLinkSet}
            placeholder="Pegar o escribir url"
            ref={urlInput}
            type="url"
          />
        </div>
      )}

      <button
        data-active={showModal}
        data-link={!!link}
        type="button"
        title={link !== "" ? link : "Agregar Link"}
        onClick={handleLinkSet}
      >
        {linkIcon}
      </button>
    </>
  );
};

export default LinkModal;
