import { Editor } from "@tinymce/tinymce-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { CreateVideoContext } from "../../contexts/CreateVideoContext";
import CopyToClipboard from "../CopyToClipboard";
import DirectVideoUpload from "./DirectVideoUpload";
import MuxVideoUploader from "./MuxVideoUploader";
import StreamPlayer from "./StreamPlayer";
import ThumbnailSwitch from "./ThumbnailSwitch";

const OttVideosForm = () => {
  const { currentValues, tags, statuses } = useContext(CreateVideoContext);

  const [reupload, setReupload] = useState(false);
  const [currentTags, setCurrentTags] = useState(
    currentValues.tags.map((tag) => ({
      value: tag.id,
      label: tag.name,
      profile: tag.taggable_id,
    })) ?? []
  );

  const submitBtn = useRef();
  const titleRef = useRef();
  const descriptionRef = useRef();

  const {
    signedId,
    videoUrl,
    videoUid,
    title,
    description,
    publishDate,
    slug,
    streamKey,
    publicUrl,
    provider,
  } = currentValues;

  const [descriptionValue, setDescriptionValue] = useState(description);
  const [btnDisable, setBtnDisable] = useState(true);
  const [formattedSlug, setFormattedSlug] = useState(slug);

  const [allowSave, setAllowSave] = useState(
    !!(signedId || videoUrl || videoUid || streamKey)
  );

  const checkFields = () => {
    if (
      [titleRef, descriptionRef].every((i) => i.current.value.trim() !== "") &&
      allowSave
    ) {
      setBtnDisable(false);
      return true;
    } else {
      setBtnDisable(true);
      return false;
    }
  };

  const handleSlug = (str) => {
    console.log(str);
    str = str.replace(/^\s+|\s+$/g, "");
    str = str.toLowerCase();
    str = str
      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
    setFormattedSlug(str);
  };

  useEffect(() => {
    if (!!(signedId || videoUrl || videoUid || streamKey)) return;

    if (allowSave && checkFields()) {
      submitBtn.current.click();
    }
  }, [allowSave]);

  useEffect(() => {
    checkFields();
  }, [descriptionValue]);

  return (
    <div className="row">
      <div className="row col-6 mb-3">
        <div className={`col-12 ${slug ? "order-2 mt-3" : "mb-3"}`}>
          <label name="ott_video[title]" className="form-label">
            Titulo del video
          </label>
          <input
            defaultValue={title}
            ref={titleRef}
            onChange={checkFields}
            type="text"
            name="ott_video[title]"
            className="form-control"
            placeholder="Este es el titulo del video"
          />
        </div>
        <div className={`col-12 ${slug && "order-1"}`}>
          {(provider == "mux" || reupload) && (
            <>
              {slug && (
                <label name="ott_video[title]" className="form-label">
                  {provider == "mux" ? "Preview del video" : "Resubir video"}
                </label>
              )}
              <MuxVideoUploader onSuccess={() => setAllowSave(true)} />
            </>
          )}

          {provider == "cloudflare" &&
            !reupload &&
            (streamKey ? (
              <>
                <button
                  onClick={() => setReupload(true)}
                  className="reupload-btn"
                >
                  Resubir video
                </button>
                <StreamPlayer controls src={streamKey} />
              </>
            ) : (
              <>
                <button
                  onClick={() => setReupload(true)}
                  className="reupload-btn"
                >
                  Resubir video
                </button>
                <DirectVideoUpload
                  name="ott_video[video_signed_id]"
                  videoData={{ signedId, videoUrl, videoUid }}
                  addClass={`ott-video__video ${
                    videoUid ? "" : "video-aspect"
                  }`}
                  onEnd={() => setAllowSave(true)}
                />
              </>
            ))}
        </div>
      </div>

      {(videoUid || streamKey) && (
        <div className="col-6 mb-3">
          <div className="d-flex justify-content-between align-items-center">
            <label name="ott_video[thumbnail]" className="form-label">
              Miniatura del video
            </label>
          </div>

          <ThumbnailSwitch />
        </div>
      )}

      <div className="col-6 mb-3">
        <label name="ott_video[description]" className="form-label">
          Descripción
        </label>
        <input
          value={descriptionValue}
          ref={descriptionRef}
          name="ott_video[description]"
          type="hidden"
        />
        <Editor
          value={descriptionValue}
          onEditorChange={(newValue) => setDescriptionValue(newValue)}
          remove_script_host={false}
          convert_urls={false}
          relative_urls={false}
          init={{
            height: 280,
            menubar: false,
            convert_urls: false,
            relative_urls: false,
            plugins: [
              "advlist hr autolink lists link charmap print preview anchor",
              "searchreplace visualblocks media code fullscreen",
              "insertdatetime media table paste code emoticons wordcount",
              "searchreplace visualblocks code fullscreen code",
            ],
            toolbar:
              "formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent hr | removeformat  | emoticons | link + undo redo code",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </div>

      <div className={`row ${slug ? "col-6" : "col-12"}`}>
        <div className="col-6">
          <label name="ott_video[publish_date]" className="form-label">
            Fecha de Publicación
          </label>
          <input
            defaultValue={publishDate}
            type="datetime-local"
            name="ott_video[publish_date]"
            className="form-control"
          />
        </div>

        {(videoUid || streamKey) && (
          <>
            <div className="col-6">
              <label className="form-label">Status</label>
              <select
                className="form-select"
                defaultValue={currentValues.status}
                name="ott_video[status]"
              >
                <option value="" selected disabled>
                  Elegir status
                </option>
                {Object.keys(statuses).map((value) => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}

        <div className={videoUid || streamKey ? "col-12" : "col-6"}>
          <label name="tags" className="form-label">
            Tags
          </label>

          {currentTags.map((t) => (
            <input
              type="hidden"
              value={t.value}
              name="ott_video[ott_tag_ids][]"
            />
          ))}

          <Select
            isMulti
            name="tags"
            placeholder="Buscar o asignar tags"
            options={tags.map((tag) => ({
              value: tag.id,
              label: tag.name,
              profile: tag.taggable_id,
            }))}
            styles={{
              option: (styles, { data, isFocused }) => ({
                ...styles,
                backgroundColor: isFocused
                  ? data.profile
                    ? "#FFE69C"
                    : "#E9ECEF"
                  : "white",
              }),
              multiValue: (styles, { data }) => ({
                ...styles,
                backgroundColor: data.profile ? "#FFE69C" : "#E9ECEF",
              }),
              menu: (styles) => ({
                ...styles,
                zIndex: 10,
              }),
            }}
            className="basic-multi-select"
            value={currentTags}
            onChange={(e) => setCurrentTags(e)}
          />
        </div>
        {slug && (
          <div className="col-12">
            <label name="ott_video[slug]" className="form-label">
              Slug
            </label>
            <div className="d-flex">
              <input
                value={formattedSlug}
                onChange={({ target }) => handleSlug(target.value)}
                name="ott_video[slug]"
                className="form-control"
              />
              <CopyToClipboard copy={publicUrl} classname="clean-btn" />
            </div>
          </div>
        )}
      </div>
      <button className="btn btn-publish" disabled={btnDisable} ref={submitBtn}>
        Guardar
      </button>
    </div>
  );
};

export default OttVideosForm;