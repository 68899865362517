import React, { useState } from "react";
import PropTypes from "prop-types";
import ArticleTr from "./ArticleTr";

const ArticleTable = ({ articlesRails, searchBar = true }) => {
  const [searchResults, setSearchResults] = useState(articlesRails);

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setSearchResults(articlesRails);
      return;
    }

    const term = new RegExp(`${e.target.value}.*$`, "i");
    const result = searchResults.filter((e) => e.title.search(term) !== -1);

    setSearchResults(result);
  };
 
  return (
    <>
      {
        searchBar &&
          <div class="header-search item-list__top__search w-full">
            <input onChange={(e) => handleSearch(e)} placeholder="Buscar artículo" />
          </div>
      }
      <div className="p-3">
        <table className="table-general">
          <thead>
            <tr>
              <th>Título</th>
              <th>Fecha</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.slice(0, 10).map((article) => (
              <ArticleTr key={article.title} article={article} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

ArticleTable.propTypes = {
  articlesRails: PropTypes.array,
};

ArticleTable.defaultProps = {
  articlesRails: [],
};

export default ArticleTable;
