import React, { useContext, useEffect, useRef } from "react";
import { CreateVideoContext } from "../../contexts/CreateVideoContext";
import MuxPlayer from "./MuxPlayer";
import "@mux/mux-uploader/dist/mux-uploader.mjs"

const MuxVideoUpload = ({ onSuccess }) => {
  const { uploadData, currentValues: { videoUid, streamKey, provider } } = useContext(CreateVideoContext);
  const uploaderRef = useRef();

  useEffect(() => {
    uploaderRef?.current?.addEventListener("success", () => {
      onSuccess();
    });
  }, [uploaderRef]);

  return (
    <>
      {(provider == "mux" && (videoUid || streamKey)) ? (
        <MuxPlayer />
      ) : (
        <>
          <mux-uploader ref={uploaderRef} endpoint={uploadData?.url} />
          <input type="hidden" name="ott_video[upload_id]" value={uploadData?.id} />
        </>
      )}
    </>
  );
};

export default MuxVideoUpload;