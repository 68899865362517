import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArticleTrPopup from './ArticleTrPopup';

const ArticlePopup = React.memo(({
    setShowPopUp,
    articles,
    articleArea,
    cardOptions,
    addArticleCard
}) => {
    const handleClosePopUp = () => {
        setShowPopUp({ status: false});
    }

    const [selectedOption, setSelectedOption] = useState(cardOptions.find(item => item.order == articleArea) ? cardOptions.find(item => item.order == articleArea).article : null);

    console.log(selectedOption)

    const handleSaveArticleSelected = () => {
        addArticleCard(articleArea, selectedOption);
        setShowPopUp({ status: false});
    }

    return (
        <div className="popup-container">
            <div className="popup">
                <div className="popup__header">
                    <div className="popup__header__description">
                        <h2 className="popup__header__description__title">
                            Lista General
                        </h2>
                        <p className="popup__header__description__subtitle">
                            Elige que deseas mostrar en la pagina principal
                        </p>
                    </div>
                    <div className="popup__header__close">
                        <button
                            className="popup__header__close__button"
                            onClick={ handleClosePopUp }
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <hr />
                <div className="popup__body">
                    <div className="popup__body__search">
                        <div className="input-search">
                            <div className="icon">
                                <i className="fas fa-search"></i>
                            </div>
                            <input type="text" placeholder="Buscar" />
                        </div>
                    </div>
                    <table className="tp-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Título</th>
                                <th>Fecha</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                articles.length > 0 &&
                                    articles.map(article => {
                                        let isActive = false;
                                        if (selectedOption && parseInt(selectedOption.id) == parseInt(article.id)) {
                                            isActive = true;
                                        }

                                        return (<ArticleTrPopup
                                            key={ article.id }
                                            article={article}
                                            isActive={isActive}
                                            setSelectedOption={setSelectedOption}
                                        />);
                                    }) 
                            }
                        </tbody>
                    </table>
                    {
                        articles.length === 0 &&
                            <div className="empty-table">
                                <p className="empty-table__description">No existen articulos que mostrar</p>
                            </div>
                    }
                </div>
                <div className="popup__footer">
                    <div className="popup__footer__buttons">
                        <button
                            className="btn btn-outline-primary mr-1"
                            onClick={ handleClosePopUp }
                        >
                            Cancelar
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={handleSaveArticleSelected}
                        >Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
    );
});

ArticlePopup.propTypes = {
    articles: []
}

ArticlePopup.propTypes = {
    setShowPopUp: PropTypes.func,
    articles: PropTypes.array,
    articleArea: PropTypes.integer,
    cardOptions: PropTypes.object,
    addArticleCard: PropTypes.func
}

export default ArticlePopup;