import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const PlaylistForm = ({ formData, addFormData, tags }) => {
  const orders = [
    {
      id: 'date',
      name: 'Fecha de creacion'
    },{
      id: 'alphabetical',
      name: 'Nombre'
    },{
      id: 'manual',
      name: 'Manual'
    }
  ];

  const tagFormat = (tagList) => tagList.map(tag => ({value: tag.id, label: tag.name, profile: tag.taggable_id}));
  const tagList = tagFormat(tags);

  const handleEditOrder = ({ target }) => {
    console.log(target);
    addFormData('order', target.value);
  };

  const handleEditTitle = ({ target }) => addFormData('title', target.value);
  const handleEditTags = (tagsValues) => addFormData('tags', tagsValues);

  return (
    <div className='playlist__form'>
      <div className='mb-3'>
        <label className='form-label'>
          Titulo de Playlist
        </label>
        <input
          type='text'
          name='title'
          placeholder='Este es el titulo de la playlist'
          className='form-control'
          onChange={ handleEditTitle }
          value={ formData.title }
        />
      </div>
      <div className='mb-3'>
        <label className='form-label'>Ordenar por</label>
        <select id="disabledSelect"
          className="form-select"
          value={ formData.order }
          onChange={(e) => handleEditOrder(e)}
        >
          <option value=''>Elegir orden</option>
          {
            orders.map(({id, name}) => (
              <option key={ name } value={ id }>{ name }</option>
            ))
          }
        </select>
      </div>
      <div className='mb-3'>
        <label className='form-label'>Asignar Tags</label>
        <Select
          isMulti
          name="tags"
          options={tagList}
          className="basic-multi-select"
          placeholder="Buscar o asignar Tags"
          onChange={handleEditTags}
          value={formData.tags}
          styles={{
                option: (styles, { data, isFocused }) => ({
                  ...styles,
                  backgroundColor: isFocused
                    ? data.profile
                      ? "#FFE69C"
                      : "#E9ECEF"
                    : "white",
                }),
                multiValue: (styles, { data }) => ({
                  ...styles,
                  backgroundColor: data.profile ? "#FFE69C" : "#E9ECEF",
                }),
                menu: (styles) => ({
                  ...styles,
                  zIndex: 10,
                }),
              }}
        />
      </div>
    </div>
  )
}

PlaylistForm.propTypes = {
  selectOption: PropTypes.func.isRequired
}

export default PlaylistForm;
