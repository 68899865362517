import React, { useState } from "react";
import Select from "react-select";

const periodOptions = {
  'weekly': { value: 'weekly', label: 'Semanal' },
  'biweekly': { value: 'biweekly', label: 'Cada 2 semanas' },
  'triweekly': { value: 'triweekly', label: 'Cada 3 semanas' },
  'monthly': { value: 'monthly', label: 'Mensual' },
}

const SelectPeriod = ({ period, addNow }) => {
  const [currentPeriod, setCurrentPeriod] = useState(periodOptions[period] ?? 0);
  const [enablePeriod, setEnablePeriod] = useState(period !== 'disabled');

  return (
    <div className="select-period">
      <div className="select-period__title">
        <label className="form-label">Periodicidad</label>
        <label className="switch mb-2 mx-3">
          <input
            type="checkbox"
            checked={enablePeriod}
            onChange={({ target: { checked } }) => setEnablePeriod(checked)}
          />
          <span className="slider" />
        </label>
      </div>
      <Select
        name="program[period]"
        placeholder="Asignar periodicidad"
        isDisabled={!enablePeriod}
        options={Object.keys(periodOptions).map((k) => periodOptions[k])}
        value={currentPeriod}
        onChange={setCurrentPeriod}
      />
      <div className="select-period__title mt-3">
      <label className="form-label">Agregar a programación desde esta semana</label>
        <label className="switch mb-2 mx-3">
          <input
            type="checkbox"
            checked={addNow}
            name="add_to_current_week"
          />
          <span className="slider" />
        </label>
      </div>
    </div>
  );
};

export default SelectPeriod;
