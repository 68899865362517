import { Stream } from "@cloudflare/stream-react";
import React from "react";

// Using stream live_input from cloudflare as src

const StreamPlayer = ({ src }) => (
  <Stream controls src={src} />
);

export default StreamPlayer;
