import React, { useState } from "react";
import LiveChat from "./LiveChat";
import EmojiReactions from "./EmojiReactions.jsx";
import VideoPlayer from "./videos/VideoPlayer.jsx";
import CommentTools from "./CommentTools.jsx";

const ChatMod = ({ liveChat, player, token, feedId }) => {
  const [currentComment, setCurrentComment] = useState({});
  const { id } = currentComment;

  return (
    <>
      <div className="live-chat col-12 col-lg-6">
        <LiveChat {...liveChat} onMessageSelect={setCurrentComment} />
      </div>

      <div className="col-12 col-lg-6 px-3 d-flex flex-lg-column flex-column-reverse">
        <div className="video-aspect mt-3 mt-lg-0 mb-lg-3">
          <mux-player accent-color="#CF2032" playback-id={player.playback} />;
        </div>

        {id && (
          <CommentTools
            message={currentComment}
            onRemove={() => setCurrentComment({})}
            feedId={feedId}
            token={token}
          />
        )}

        <EmojiReactions liveChat={liveChat.liveChat} emojis={liveChat.emojis} />
      </div>
    </>
  );
};

export default ChatMod;
