import React, { useEffect, useState } from "react";

const loadingSpinner = (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: 'auto', display: 'block' }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#fd495c" stroke="none">
      <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51"></animateTransform>
    </path>
  </svg>
);

const btnCheck = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6449 2.35C12.1949 0.9 10.2049 0 7.99488 0C3.57488 0 0.00488281 3.58 0.00488281 8C0.00488281 12.42 3.57488 16 7.99488 16C11.7249 16 14.8349 13.45 15.7249 10H13.6449C12.8249 12.33 10.6049 14 7.99488 14C4.68488 14 1.99488 11.31 1.99488 8C1.99488 4.69 4.68488 2 7.99488 2C9.65488 2 11.1349 2.69 12.2149 3.78L8.99488 7H15.9949V0L13.6449 2.35Z" fill="#F8F9FA"/>
  </svg>
)

const postToStream = async (path, body) => {
  try {
    const response = await fetch(path, {
      method: "POST",
      body: body,
    });

    return await response.json();
  } catch (error) {
    return [];
  }
};

const getStatus = async (path) => {
  try {
    const response = await fetch(path);

    return await response.json();
  } catch (error) {
    return { stream: { current: { state: "unavailable" } } };
  }
};

const statusLabel = {
  idle: "Listo",
  active: "Conectado",
};

const ToggleStream = ({ startPath, stopPath, isLive, token, checkPath }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("Actualizando");

  const handleStreamStatus = async (path) => {
    const body = new FormData();
    body.append("authenticity_token", token);

    setIsLoading(true);

    const response = await postToStream(path, body);

    if (response.url) {
      window.location = response.url;
    }
  };

  const handleCheck = async () => {
    setStatus("Actualizando");

    const response = await getStatus(checkPath);

    if (response.status) {
      const current = response.status;

      setStatus(statusLabel[current]);
    } else {
      setStatus("Listo");
    }
  };

  useEffect(() => {
    handleCheck();
  }, []);

  useEffect(() => {
    if (isLive && status == 'Listo') {
      handleStreamStatus(stopPath);
    } else if (!isLive && status == 'Conectado') {
      handleStreamStatus(startPath);
    }
  }, [status]);

  return (
    <>
      {isLoading && <div className="loading-spinner">{loadingSpinner}</div>}
      <button className="btn btn-publish" type="button" onClick={handleCheck}>
        Refrescar
        {"  "}
        {btnCheck}
      </button>
      <span className="stream-status" data-status={status}>
        {status}
      </span>
    </>
  );
};

export default ToggleStream;
