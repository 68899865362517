import ReactOnRails from "react-on-rails";
import PopUpDelete from "../bundles/components/PopUpDelete";
import PopUpNewHeroButton from "../bundles/components/PopUpNewHeroButton";
import PreviewImage from "../bundles/components/PreviewImage";
import PreviewVideo from "../bundles/components/PreviewVideo";
import ProfilePicture from "../bundles/components/ProfilePicture";
import SearchBar from "../bundles/components/SearchBar";
import SearchBarUrl from "../bundles/components/SearchBarUrl";
import Sidebar from "../bundles/components/Sidebar";
import StickyInfo from "../bundles/components/StickyInfo";
import UserOptions from "../bundles/components/UserOptions";
import DateTimeSchedule from "../bundles/form/components/DateTimeSchedule";
import InputSuggest from "../bundles/form/components/InputSuggest";
import PasswordChange from "../bundles/form/components/PasswordChange";
import TimeSchedule from "../bundles/form/components/TimeSchedule";
import UserSuggest from "../bundles/form/components/UserSuggest";
import DropZone from "../bundles/slideshow/components/DropZone";
import Slideshow from "../bundles/slideshow/containers/Slideshow";
import ArticleTable from "../bundles/todos/components/ArticleTable";
import Display from "../bundles/todos/components/Display";

// Grada components
import ListSort from "../bundles/components/listings/ListSort";
import ListUpdateField from "../bundles/components/listings/ListUpdateField";
import CopyToClipboard from "../bundles/components/CopyToClipboard";

// Ott Components
import OttVideoFilter from "../bundles/components/filters/OttVideoFilter";
import CreatePlaylist from "../bundles/components/playlists/CreatePlaylist";
import CreateVideo from "../bundles/components/videos/CreateVideo";
import ToggleStream from "../bundles/components/videos/ToggleStream";
import FavButton from "../bundles/components/videos/FavButton";
import VideoPlayer from "../bundles/components/videos/VideoPlayer";
import StreamPlayer from "../bundles/components/videos/StreamPlayer";
import FilterSort from "../bundles/components/videos/FilterSort";
import SelectPeriod from "../bundles/form/components/SelectPeriod";
import ChatMod from "../bundles/components/ChatMod";

// Congreso Components
import SearchSelect from "../bundles/components/SearchSelect";
import SortPopup from "../bundles/components/listings/SortPopup";

// This is how rails can see and use components
// We need to "register" all components in here
ReactOnRails.register({
  ArticleTable,
  CreatePlaylist,
  CopyToClipboard,
  DateTimeSchedule,
  Display,
  DropZone,
  FilterSort,
  FavButton,
  InputSuggest,
  ChatMod,
  ListSort,
  ListUpdateField,
  OttVideoFilter,
  CreateVideo,
  PasswordChange,
  PreviewImage,
  PreviewVideo,
  ProfilePicture,
  PopUpDelete,
  PopUpNewHeroButton,
  SearchBar,
  SearchBarUrl,
  SearchSelect,
  SortPopup,
  Sidebar,
  Slideshow,
  SelectPeriod,
  StickyInfo,
  StreamPlayer,
  TimeSchedule,
  ToggleStream,
  UserOptions,
  UserSuggest,
  VideoPlayer,
});
