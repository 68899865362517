import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DaySchedule from "./DaySchedule";
import Select from "react-select";

const daysOptions = [
  { value: "monday", label: "Lunes" },
  { value: "tuesday", label: "Martes" },
  { value: "wednesday", label: "Miercoles" },
  { value: "thursday", label: "Jueves" },
  { value: "friday", label: "Viernes" },
  { value: "saturday", label: "Sábado" },
  { value: "sunday", label: "Domingo" },
];

const TimeSchedule = ({ schedule, params }) => {
  const parseDays = (obj) => {
    if (obj !== null) {
      const schedule = obj.week;

      if (schedule !== null) {
        return schedule;
      }
    }

    return {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    };
  };

  const [week, setWeek] = useState(parseDays(schedule));

  const handleTimes = (name, day) => {
    setWeek({ ...week, [name]: day });
  };

  const getDays = () =>
    daysOptions.filter((d) =>
      Object.keys(week)
        .filter((i) => week[i].length)
        .includes(d.value)
    );

  const [days, setDays] = useState(getDays());

  useEffect(() => {
    if (schedule || !params.day || !params.time) return;

    setDays([daysOptions[params.day]]);
    setWeek({
      ...week,
      [daysOptions[params.day].value]: [{ opening: params.time, closing: "" }],
    });
  }, []);

  const handleChangeDays = (options) => {
    setDays(options);

    // Get day names
    const newDays = options.map((d) => d.value);

    // Filter week using days and parse new week object
    const newWeek = Object.fromEntries(
      Object.entries(week).filter((i) => newDays.includes(i[0]))
    );

    setWeek(newWeek);
  };

  return (
    <div className="schedule-selector">
      <label className="form-label">Definir horarios</label>
      <Select
        isMulti
        value={days}
        onChange={(e) => handleChangeDays(e)}
        placeholder="Asignar horarios"
        options={daysOptions}
      />

      {days.map((d) => (
        <DaySchedule
          day={week[d.value]}
          name={d.value}
          updateSchedules={handleTimes}
          title={d.label}
        />
      ))}

      <input type="hidden" value={JSON.stringify(week)} name="schedule[week]" />
    </div>
  );
};

TimeSchedule.propTypes = {
  name: PropTypes.string.isRequired,
  schedule: PropTypes.shape({
    sunday: PropTypes.arrayOf(PropTypes.object),
    monday: PropTypes.arrayOf(PropTypes.object),
    tuesdayday: PropTypes.arrayOf(PropTypes.object),
    wednesday: PropTypes.arrayOf(PropTypes.object),
    thursday: PropTypes.arrayOf(PropTypes.object),
    friday: PropTypes.arrayOf(PropTypes.object),
    saturday: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default TimeSchedule;
