import React, { useEffect, useReducer } from "react";
import consumer from "../../channels/consumer";
import reducer from "./emojiReducer";

const EmojiReactions = ({ liveChat, emojis }) => {
  console.log(liveChat, emojis)
  
  const [state, dispatch] = useReducer(reducer, {
    chatroom: { name: liveChat.name, id: liveChat.id, emojis },
    chatConnection: {},
  });

  const { chatroom, chatConnection } = state;

  useEffect(() => {
    const createSocket = () => {
      const subscription = consumer.subscriptions.create(
        {
          channel: "EmojiReactionsChannel",
          room: chatroom.name,
        },
        {
          received: (data) => {
            const { action, emoji } = data;
            dispatch({ type: action, payload: emoji });
          },
        }
      );
      dispatch({ type: "chatConnection", payload: subscription });
    };

    if (chatroom.name) {
      createSocket();
    }
  }, [chatroom.name, chatroom.id]);

  const handleReact = (emoji) => {
    console.log(emoji);

    chatConnection.send({
      chatroom_id: chatroom.id,
      emoji: emoji,
    });
  };

  return (
    <div className="live-reaction">
      {Object.keys(chatroom.emojis).map((emoji) => (
        <button
          className="live-reaction__item"
          onClick={() => handleReact(emoji)}
        >
          <div className="live-reaction__item__emoji">{emoji}</div>
          <span>{chatroom.emojis[emoji]}</span>
        </button>
      ))}
    </div>
  );
};

export default EmojiReactions;
